import {
	ColumnDef,
	OnChangeFn,
	RowModel,
	RowSelectionState,
	SortingState
} from "@tanstack/react-table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IcoArrowUp, IcoCheck } from "assets/icons";
import { Button, ColoredTag, Pagination, Table } from "components";
import ModalCancelConfirm from "components/ModalCancelConfirm";

import colors from "colors";

import { TaskStatus } from "models/covenant/enums";
import { Task } from "models/covenant/Task";

import { FormatContractNameHelper } from "helpers";
import { useSelector } from "react-redux";
import { TaskService } from "services/tasks";
import { RootState } from "store";
import CompleteTaskDrawer from "./CompleteTaskDrawer";
import "./styles.scss";

type TaskTableProps = {
	content: Task[];
	pageSize: number;
	currentPage: number;
	totalPages: number;
	onPageChange: (selectedPage: number, pageSize: number) => void;
	sorting?: SortingState;
	setSorting?: OnChangeFn<SortingState>;
	reloadData: () => void;
	statusFilter: string;
};

export default function TasksTable({
	content,
	pageSize,
	currentPage,
	totalPages,
	onPageChange,
	sorting,
	setSorting,
	reloadData,
	statusFilter
}: TaskTableProps) {
	const navigate = useNavigate();
	const taskService = new TaskService();
	const [isScrolledDown, setIsScrolledDown] = useState(false);
	const [isCompleteTaskConfirmModalOpen, setIsCompleteTaskConfirmModalOpen] =
		useState(false);
	const [isModalCompleteTaskOpen, setIsModalCompleteTaskOpen] = useState(false);
	const [completingTask, setCompletingTask] = useState<Task>();
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
	const [selectionRowModel, setSelectedRowModel] = useState<RowModel<any>>();
	const { isAnalyst } = useSelector((state: RootState) => state.auth);

	const getStatusType = (
		statusCode: number,
		dueDate: Date
	): "default" | "success" | "alert" | "error" => {
		const today = new Date();
		today.setHours(dueDate.getHours());
		today.setMinutes(dueDate.getMinutes());
		today.setSeconds(dueDate.getSeconds());
		today.setMilliseconds(dueDate.getMilliseconds());
		switch (statusCode) {
			case TaskStatus.fulfilled.code:
				return "success";
			case TaskStatus.unfulfilled.code:
				return "alert";
			case TaskStatus.pending.code:
				return today <= dueDate ? "default" : "error";
			default:
				return "default";
		}
	};

	const getStatus = (statusCode: number, dueDate: Date): string => {
		const today = new Date();
		today.setHours(dueDate.getHours());
		today.setMinutes(dueDate.getMinutes());
		today.setSeconds(dueDate.getSeconds());
		today.setMilliseconds(dueDate.getMilliseconds());

		switch (statusCode) {
			case TaskStatus.fulfilled.code:
				return "Concluído";
			case TaskStatus.unfulfilled.code:
				return "Não iniciado";
			case TaskStatus.siacorp.code:
				return "Consultar legado (Siacorp)";
			case TaskStatus.pending.code:
				return today <= dueDate ? "Não iniciado" : "Em atraso";
			default:
				return "Não iniciado";
		}
	};
	const generateCommonColumns = (): ColumnDef<Task>[] => {
		return [
			{
				id: "clauseNumber",
				accessorKey: "clause.clauseNumber",
				header: "Cláusula",
				meta: {
					columnClass: "clause-number-column"
				}
			},
			{
				id: "clauseCategory",
				accessorKey: "clause.clauseCategory.name",
				header: "Categoria",
				cell: (row) => (
					<div className="row-name">
						<p>{row.getValue() as string}</p>
					</div>
				),
				meta: {
					columnClass: "clause-category-column"
				}
			},
			{
				id: "covenantNumber",
				accessorKey: "clause.covenant.covenantNumber",
				header: "Contrato",
				cell: (row) => (
					<div className="row-name">{row.getValue() as string}</div>
				),
				meta: {
					columnClass: "covenant-number-column"
				}
			},
			{
				id: "contractingCompanies",
				accessorFn: (row) =>
					row.clause?.covenant?.contractingCompanies
						? row.clause.covenant.contractingCompanies
								.map((company: any) => company.name)
								.join(", ")
						: "",
				cell: (row) => (
					<div className="row-name">
						{FormatContractNameHelper.getContractName(row.getValue() as string)}
					</div>
				),
				header: "Contratante",
				enableSorting: false,
				meta: {
					columnClass: "contractingCompanies-column"
				}
			},

			{
				id: "teams",
				accessorKey: "teamsNames",
				header: "Time vinculado",
				enableSorting: false,
				cell: (row) => {
					return (
						<div className="row-name">
							<p>{row.getValue() as string}</p>
						</div>
					);
				},
				meta: {
					columnClass: "teams-column"
				}
			},
			{
				id: "dueDate",
				accessorFn: (row) => (row.dueDate ? moment(row.dueDate) : ""),
				header: "Vencimento",
				cell: (row) =>
					row.getValue()
						? moment(row.getValue() as Date).format("DD/MM/YYYY")
						: "",
				enableSorting: true,
				meta: {
					columnClass: "completionDate-column"
				}
			},
			{
				id: "status",
				accessorKey: "status.name",
				header: "Status",
				cell: ({ row }) => {
					const { original } = row;
					const statusType = getStatusType(
						original.status.code,
						original.dueDate
					);
					const status = getStatus(original.status.code, original.dueDate);
					return (
						<ColoredTag
							text={status}
							type={statusType}
							className="task-status-tag"
						/>
					);
				},
				enableSorting: statusFilter === "completed",
				meta: {
					columnClass: "status-column"
				}
			}
		];
	};

	const columns: ColumnDef<Task>[] = [...generateCommonColumns()];
	columns.splice(columns.findIndex((col) => col.id === "team") + 1, 0, {
		id: "frequency",
		accessorKey: "frequency.name",
		header: "Periodicidade",
		meta: {
			columnClass: "frequency-column"
		}
	});

	const columnsCompleted: ColumnDef<Task>[] = [...generateCommonColumns()];

	const completeTask = async () => {
		return taskService
			.complete(completingTask!.clause.clauseId, {
				status: TaskStatus.fulfilled.code,
				dueDate: completingTask!.dueDate
			})
			.then(() => onPageChange(currentPage, pageSize))
			.finally(() => {
				setCompletingTask(undefined);
				setIsCompleteTaskConfirmModalOpen(false);
			});
	};

	const selectionActions = () => {
		if (statusFilter !== "completed" && statusFilter !== "siacorp") {
			return (
				<Button
					styled="secondary"
					onClick={() => setIsModalCompleteTaskOpen(true)}
				>
					<IcoCheck size="20" />

					<div>
						{Object.keys(rowSelection).length > 1
							? "Concluir tarefas"
							: "Concluir tarefa"}
					</div>
				</Button>
			);
		}
		return null;
	};

	const selectionLabel = () => {
		const numberRows = Object.keys(rowSelection).length;
		const labelName = numberRows > 1 ? "tarefas" : "tarefa";
		const labelSelected = numberRows > 1 ? "selecionadas" : "selecionada";
		return (
			<>
				<b>{`${numberRows} ${labelName} `}</b>
				{`${labelSelected} no total`}
			</>
		);
	};

	const checkScrollTop = () => {
		const minPixelScrolled = 300;
		setIsScrolledDown(window.scrollY > minPixelScrolled);
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	const getSubRowComponent = (row: any, columnsSubRow: any[]) => {
		const subrowLength = isAnalyst
			? statusFilter !== "completed"
				? columnsSubRow.length - 2
				: columnsSubRow.length - 1
			: columnsSubRow.length - 2;

		return (
			<tr className="expanded-description-row" key={row.id}>
				{isAnalyst ? (
					statusFilter !== "completed" && <td className="select-column"> </td>
				) : (
					<td className="select-column"> </td>
				)}
				<td className="subrow-column" colSpan={subrowLength}>
					<div className="subrow-content">
						{row.original.clause.description}
						<div className="buttons-container">
							<Button
								cssClass="show-details-button"
								kind="default"
								styled="secondary"
								size="medium"
								onClick={() => {
									navigate(`/clauses/details/${row.original.clause.clauseId}`);
								}}
							>
								Mostrar detalhes
							</Button>
							{row.original.status.code !== TaskStatus.fulfilled.code &&
								row.original.status.code !== TaskStatus.siacorp.code && (
									<Button
										cssClass="close-task-button"
										kind="default"
										size="medium"
										onClick={() => {
											setCompletingTask(row.original);
											setIsCompleteTaskConfirmModalOpen(true);
										}}
									>
										Concluir tarefa
									</Button>
								)}
						</div>
					</div>
				</td>
				{statusFilter !== "completed" && <td className="expander-column"> </td>}
			</tr>
		);
	};

	useEffect(() => {
		window.addEventListener("scroll", checkScrollTop);
		return () => window.removeEventListener("scroll", checkScrollTop);
	}, []);

	return (
		<div className="tasks-table">
			<Table
				cssClass="heavy"
				columns={
					statusFilter === "siacorp" || statusFilter === "completed"
						? columnsCompleted
						: columns
				}
				data={content}
				sorting={sorting}
				setSorting={setSorting}
				rowSelection={
					isAnalyst
						? statusFilter !== "completed" && rowSelection
						: rowSelection
				}
				setRowSelection={setRowSelection}
				selectionActions={selectionActions()}
				selectionLabel={selectionLabel}
				setSelectedRowModel={setSelectedRowModel}
				canExpand="end"
				getRowCanExpand={(row) => !!row.original.clause?.description}
				getSubRows={(row) => (row instanceof Task ? [{ ...row }] : [])}
				getSubRowComponent={getSubRowComponent}
			/>
			<Pagination
				totalPages={totalPages}
				currentPage={currentPage}
				onPageChange={onPageChange}
				pageSize={pageSize}
			/>
			<CompleteTaskDrawer
				isOpen={isModalCompleteTaskOpen}
				onClose={() => setIsModalCompleteTaskOpen(false)}
				taskIds={
					selectionRowModel
						? selectionRowModel?.rows.map(
								(row) => row.original.taskId as string
						  )
						: []
				}
				clauseIds={
					selectionRowModel
						? selectionRowModel.rows.reduce(
								(acc: { [key: string]: string }, row) => {
									acc[row.original.clause.clauseId] = row.original.dueDate;
									return acc;
								},
								{}
						  )
						: {}
				}
				reloadTable={reloadData}
			/>

			<ModalCancelConfirm
				modalTitle="Concluir tarefa"
				modalInfo="Ao confirmar, a tarefa selecionada será concluída como cláusula atendida e não poderá ser alterada."
				isOpen={isCompleteTaskConfirmModalOpen}
				onClose={() => {
					setCompletingTask(undefined);
					setIsCompleteTaskConfirmModalOpen(false);
				}}
				onCloseButtonClick={() => {
					navigate(`/tasks/complete/${completingTask!.clause.clauseId}`, {
						state: {
							completingTask
						}
					});
				}}
				onConfirm={completeTask}
				toastSuccessTitle="Tarefa concluída"
				toastSuccessMessage="A tarefa foi concluída."
				closeLabel="Adicionar justificativa"
				kind="warning"
			/>
			{isScrolledDown && (
				<Button
					onClick={scrollToTop}
					kind="icon"
					cssClass="scroll-to-top-button"
				>
					<IcoArrowUp color={colors.neutral["high-100"]} />
				</Button>
			)}
		</div>
	);
}
